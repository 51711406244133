/* eslint-disable no-unused-vars */
import '../public/ui/styles/app.css';
import '../public/ui/styles/Coves.css';
import '../public/ui/styles/Gotham.css';

import * as Sentry from '@sentry/node';
import axios from 'axios';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import '../public/ui/styles/reskin-styles/AboutUsReskin.css';
import '../public/ui/styles/reskin-styles/Book.css';
import '../public/ui/styles/reskin-styles/BookingScheduleReskin.css';
import '../public/ui/styles/reskin-styles/BookingSummaryReskin.css';
import '../public/ui/styles/reskin-styles/BookTopHero.css';
import '../public/ui/styles/reskin-styles/Footer.css';
import '../public/ui/styles/reskin-styles/Header.css';
import '../public/ui/styles/reskin-styles/Hero.css';
import '../public/ui/styles/reskin-styles/Index.css';
import '../public/ui/styles/reskin-styles/ServicesReskin.css';
import '../public/ui/styles/Tiempos.css';
import '../public/ui/styles/reskin-styles/ServicesDescription.css';
import '../public/ui/styles/reskin-styles/Blog.css';
import '../public/ui/styles/reskin-styles/Contact.css';
import '../public/ui/styles/reskin-styles/StickyFooter.css';
import '../public/ui/styles/reskin-styles/WhyTwoMaids.css';
import '../public/ui/styles/reskin-styles/Reviews.css';
import '../public/ui/styles/reskin-styles/Careers.css';

// import CloudinaryContext from 'cloudinary-react/lib/components/CloudinaryContext';
// import { FranchiseeProvider } from '../context/Franchisee';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { wrapper } from '../app/store';

declare global {
  interface Window {
    gtag;
    _loq;
  }
  namespace NodeJS {
    interface Global {
      analytics: any;
      CollectJS: any;
    }
  }
}

interface TwoMaidsSiteProps extends AppProps {
  err: any;
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
  });
}

function App({ Component, pageProps, err }: TwoMaidsSiteProps) {
  const router = useRouter();
  const pageview = (url: URL, trackingId) => {
    try {
      if (window && window.gtag && typeof window.gtag !== 'undefined') {
        window?.gtag('config', trackingId, {
          page_path: url,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let franchiseeData;
    axios
      .get('/api/tracking-session/')
      .then((res) => {
        franchiseeData = res.data.data.trackingId;

        const handleRouteChange = (url: URL) => {
          pageview(url, franchiseeData);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
          router.events.off('routeChangeComplete', handleRouteChange);
        };
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, [router.events]);

  return (
    // <CloudinaryContext cloudName={process.env.cloudinaryCloudName}>
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} err={err} />
    </>
    // </CloudinaryContext>
  );
}

export default wrapper.withRedux(App);
