import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../app/store';

export interface UserState {
  firstName?: string;
  lastName?: string;
  email?: string;
  phonePrimary?: string;
  bedrooms?: number;
  bathrooms?: number;
  people?: number;
  pets?: number;
  squareFootage?: number;
  clientID?: string;
  contactID?: string;
  locationID?: string;
  lockID?: string;
  serviceTypeId?: string;
  dayID?: string;
  routeID?: string;
  startTime?: string;
  selectedPackage?: any;
  loading?: boolean;
  serviceFee?: number;
  quoteID?: number;
  zipCode?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  addressZipCode?: string;
  billingAddressLineOne?: string;
  billingAddressLineTwo?: string;
  billingCity?: string;
  billingState?: string;
  billingZipCode?: string;
  ipAddress?: string;
  discountApplied?: boolean;
  tip?: number;
  vonigoStore?: boolean;
  selectedPackageType?: string;
  serviceFeeItemID?: number;
  workOrderNumber?: string | number;
  franchiseID?: string | number;
  discountPriceItemID?: string | number;
  taxID?: string | number;
  vonigoFranciseeSlug?: string;
}
const initialState: UserState = {
  firstName: '',
  lastName: '',
  email: '',
  phonePrimary: '',
  bedrooms: 0,
  bathrooms: 0,
  people: 0,
  pets: 0,
  squareFootage: 0,
  clientID: '',
  contactID: '',
  locationID: '',
  lockID: '',
  serviceTypeId: '',
  dayID: '',
  routeID: '',
  startTime: '',
  selectedPackage: {},
  loading: false,
  serviceFee: 0,
  quoteID: 0,
  zipCode: '',
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  state: '',
  addressZipCode: '',
  billingAddressLineOne: '',
  billingAddressLineTwo: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
  ipAddress: '',
  discountApplied: false,
  tip: 0,
  vonigoStore: false,
  selectedPackageType: '',
  serviceFeeItemID: 0,
  workOrderNumber: 0,
  franchiseID: 0,
  discountPriceItemID:0,
  taxID: 0,
  vonigoFranciseeSlug:'home'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setBillingInfo: (state, action: PayloadAction<UserState>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phonePrimary = action.payload.phonePrimary;
    },
    setHomeInfo: (state, action: PayloadAction<UserState>) => {
      state.bedrooms = action.payload.bedrooms;
      state.bathrooms = action.payload.bathrooms;
      state.people = action.payload.people;
      state.pets = action.payload.pets;
      state.squareFootage = action.payload.squareFootage;
    },
    setLocationID: (state, action: PayloadAction<UserState>) => {
      state.locationID = action.payload.locationID;
    },
    setFranchiseID: (state, action: PayloadAction<UserState>) => {
      state.franchiseID = action.payload.franchiseID;
    },
    setDiscountPriceItemID: (state, action: PayloadAction<UserState>) => {
      state.discountPriceItemID = action.payload.discountPriceItemID;
    },
    setTaxID: (state, action: PayloadAction<UserState>) => {
      state.taxID = action.payload.taxID;
    },
    setClientID: (state, action: PayloadAction<UserState>) => {
      state.clientID = action.payload.clientID;
    },
    setContactID: (state, action: PayloadAction<UserState>) => {
      state.contactID = action.payload.contactID;
    },
    setSelectedTimeSlot: (state, action: PayloadAction<UserState>) => {
      state.startTime = action.payload.startTime;
      state.routeID = action.payload.routeID;
      state.dayID = action.payload.dayID;
    },
    setLockID: (state, action: PayloadAction<UserState>) => {
      state.lockID = action.payload.lockID;
    },
    vonigoFranciseeSlug: (state, action) => {
      state.vonigoFranciseeSlug = action.payload;
    },
    setSelectedPackageInStore: (state, action) => {
      state.selectedPackage = action.payload;
    },
    setVonigoLoading: (state, action) => {
      state.loading = action.payload;
    },
    setServiceFee: (state, action) => {
      state.serviceFee = action.payload.fee;
      state.serviceFeeItemID = action.payload.itemID;
    },
    setQuote: (state, action) => {
      state.quoteID = action.payload;
    },
    setCookieData: (state, action) => {
      if (action.payload) {
        return { ...action.payload };
      }
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setAddress: (state, action) => {
      state.addressLineOne = action.payload.addressLineOne;
      state.addressLineTwo = action.payload.addressLineTwo;
      state.addressZipCode = action.payload.addressZipCode;
      state.city = action.payload.city;
      state.state = action.payload.state;
    },
    setBillingAddress: (state, action) => {
      state.billingAddressLineOne = action.payload.billingAddressLineOne;
      state.billingAddressLineTwo = action.payload.billingAddressLineTwo;
      state.billingZipCode = action.payload.billingZipCode;
      state.billingCity = action.payload.billingCity;
      state.billingState = action.payload.billingState;
      state.ipAddress = action.payload.ipAddress;
    },
    setDiscountApplied: (state, action) => {
      state.discountApplied = action.payload;
    },
    setTip: (state, action) => {
      let tip = action.payload;
      if (typeof tip === typeof '') tip = Number(tip);
      state.tip = tip;
    },
    setVonigoStoreFlag: (state, action) => {
      state.vonigoStore = action.payload;
    },
    setSelectedPackageType: (state, action) => {
      state.selectedPackageType = action.payload;
    },
    setResetStore: (state) => {
      state.firstName = '';
      state.lastName = '';
      state.email = '';
      state.phonePrimary = '';
      state.bedrooms = 0;
      state.bathrooms = 0;
      state.people = 0;
      state.pets = 0;
      state.squareFootage = 0;
      state.clientID = '';
      state.contactID = '';
      state.locationID = '';
      state.lockID = '';
      state.serviceTypeId = '';
      state.dayID = '';
      state.routeID = '';
      state.startTime = '';
      state.selectedPackage = {};
      state.loading = false;
      state.serviceFee = 0;
      state.quoteID = 0;
      state.zipCode = '';
      state.addressLineOne = '';
      state.addressLineTwo = '';
      state.city = '';
      state.state = '';
      state.addressZipCode = '';
      state.billingAddressLineOne = '';
      state.billingAddressLineTwo = '';
      state.billingCity = '';
      state.billingState = '';
      state.billingZipCode = '';
      state.ipAddress = '';
      state.discountApplied = false;
      state.tip = 0;
      state.selectedPackageType = '';
      state.workOrderNumber = 0;
      state.taxID =0;
      state.franchiseID = 0;
      state.discountPriceItemID = 0;
    },
    setWorkOrderNumber: (state, action) => {
      state.workOrderNumber = action.payload;
    },
  },
});

export const {
  setBillingInfo,
  setHomeInfo,
  setLocationID,
  setClientID,
  setContactID,
  setSelectedTimeSlot,
  setLockID,
  setSelectedPackageInStore,
  setVonigoLoading,
  setServiceFee,
  setQuote,
  setCookieData,
  setZipCode,
  setAddress,
  setDiscountApplied,
  setBillingAddress,
  setTip,
  setVonigoStoreFlag,
  setSelectedPackageType,
  setResetStore,
  setWorkOrderNumber,
  setFranchiseID,
  setDiscountPriceItemID,
  setTaxID,
  vonigoFranciseeSlug
} = userSlice.actions;

export const selectUserName = (state) => state.user.name;

export default userSlice.reducer;
